import axios from 'axios';
import authHeader from './auth-header';

let API_URL;
let CHAT_URL;
let AUTH_URL;
let WEBSHOP_API;

// Auth Server
process.env.NODE_ENV === 'production' ?
  AUTH_URL = `https://accounts.liox.io` :
  AUTH_URL = 'https://devaccounts.liox.io';

// API URL
process.env.NODE_ENV === 'production' ? 
  API_URL = `https://api-${window.location.hostname}` :
  API_URL = 'http://localhost:32773';

// Shop URL
process.env.NODE_ENV === 'production' ? 
  WEBSHOP_API = `https://api-webshop.liox.io` :
  WEBSHOP_API = 'http://localhost:32771';

// Chat URL
process.env.NODE_ENV === 'production' ? 
  CHAT_URL = `https://api-chat.liox.io` :
  CHAT_URL = 'http://localhost:8021';

class APIService {

  getAuthHeader(encodedUserPswd){
    return {
      Authorization: 'Basic ' + encodedUserPswd,
      "Content-Type": "application/x-www-form-urlencoded",
    }
  }

  getWebshopHost() {
    return WEBSHOP_API.trim();
  }

  getApiHost(){
    return API_URL.trim();
  }

  getChatHost(){
    return CHAT_URL.trim();
  }

  getAuthHost(){
    return AUTH_URL.trim();
  }

  getVueElementById(id){
    if(document.getElementById(id) != null){
      return document.getElementById(id).__vue__;
    }
  }

  getForm(event) {
    return window.event.srcElement || event.target;
  }

  getFormData(form) {
    const formData = Array.from(form.elements).filter(
      tag => ["select", "textarea", "input"].includes(tag.tagName.toLowerCase())
    );
    const data = {}; // need to convert it before using not with XMLHttpRequest
    formData.forEach(
      element => {
        let value = element.value
        if (element.type === "checkbox" || element.type === "radio") {
          value = element.checked
        }
        Object.assign(data, {
          [element.name]: value
        })
      }
    );
    return data;
  }

  getMultipartFormData(files) {
    let formData = new FormData();
    for (let [key, values] of Object.entries(files)) {
      if( values === null ){
        return formData;
      }
      if( typeof values.length === 'undefined' ){
        formData.append(`${key}[]`, values);
      }
      for (var i = 0; i < values.length; i++) {
        formData.append(`${key}[]`, values[i].file);
      }
    }
    return formData;
  }

  setResponse(values, array = 'messages'){

    const response = this.getVueElementById("response");
    response[array].push(values);

    if(typeof response !== "undefined") {
      for (let [key, value] of Object.entries(values)) {
        response[key] = value;
      }
    }
  }

  setRegisterResponse(values){
    const response = this.getVueElementById("response");
    if(typeof response !== "undefined") {
      for (let [key, value] of Object.entries(values)) {
        response[key] = value;
      }
    }
  }

  setProgressResponse(values){
    const response = this.getVueElementById("response");
    response.changeMessage(values);
  }

  back(){
    history.back()
  }

  reload(){
    window.location.reload()
  }

  closeProgressResponse(key){
    // console.log("Welcher Key wird geschlossen? auf APIService=", key);
    const response = this.getVueElementById("response");
    response.close(key);
  }

  removeUser(){
    localStorage.clear();
    window.location.href = `${process.env.BASE_URL}`;
  }

  setToken(token){
    localStorage.setItem('token', JSON.stringify(token));
  }

  setUser(user){
    localStorage.setItem('user', JSON.stringify(user));
  }

  /* Services */

  /**
   * @param {String} username
   * @param {String} password
   * @param {Base64} client
   * @param {Boolean} remember
  */
  async auth_Login(username, password, client, remember) {
    const data = new URLSearchParams();
    data.append("username", username);
    data.append("grant_type", "password");
    data.append("password", password);
    data.append("remember", remember);
    try {
      const response = await axios.post(AUTH_URL + '/api/v1/oauth2/token', data, {
        headers: this.getAuthHeader(client)
      });
      this.setToken(response.data);
      this.setUser({ role: response.data.scope.split(" ").reverse().shift(), app: client });
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || "API Service | error";
      this.setResponse({ message: errorMessage, color: "red" });
      return Promise.reject(error);
    }
  }

  /**
   * @param {String} type
   * @param {String} code
   * @param {Base64} client
  */
  async auth_SSO(type, code, client) {
    const data = new URLSearchParams();
    data.append("code", code);
    data.append("grant_type", "authorization_code");
    data.append("redirect_uri", location.protocol + '//' + location.host);
    data.append("remember", true);
    return await axios.post(AUTH_URL + `/api/v1/oauth2/${type}`, data, { headers: this.getAuthHeader(client) } )
    .then(response => {    // store.dispatch('fetch', {
      //   endpoint: `/api/v1/users/me`,
      //   state: 'webshop_user',
      //   host: 'getWebshopHost'
      // })
      // store.dispatch('fetch', {
      //   endpoint: `/api/v1/licences/${this.$store.state.main.config.result.licence}`,
      //   state: 'licence',
      //   host: 'getAuthHost'
      // })
      this.setToken(response.data);
      this.setUser({ role: response.data.scope.split(" ").reverse().shift(), app: client});
      return Promise.resolve(response.data);
    }).catch((error) => {
      this.setResponse({message: error.response.data.message, color: "red"});
      return Promise.reject(error);
    });
  }

  async logout(){
    return await axios.delete(AUTH_URL + "/api/v1/tokens", { headers: authHeader('access_token') })
    .then(() => {
      this.removeUser();
    }).catch(() => {
      this.removeUser();
    })
  }

  /**
   * @param {Promise} originalRequest
  */
  async resolveOrigin(originalRequest){
    let token = JSON.parse(localStorage.getItem('token'));
    const retryOriginalRequest = new Promise((resolve) => {
      originalRequest.headers.Authorization = 'Bearer ' + token.access_token
      resolve( axios(originalRequest))
    })
    return await retryOriginalRequest;
  }

  /**
   * @param {Base64} client
  */
  async refreshToken(client){
    let token = JSON.parse(localStorage.getItem('token'));
    const data = new URLSearchParams();
    data.append("grant_type", "refresh_token");
    data.append("refresh_token", token.refresh_token);
    return await axios.post(AUTH_URL + '/api/v1/oauth2/token', data, { headers: this.getAuthHeader(client) } )
    .then((response) => {
      localStorage.setItem('token', JSON.stringify(response.data));
      return Promise.resolve(response.data);
    }).catch((error) => {
      return Promise.reject(error);
    })
  }

  /**
   * @param {Base64} client
  */
  async uploadToken(client){
    let token = JSON.parse(localStorage.getItem('token'));
    const data = new URLSearchParams();
    data.append("grant_type", "refresh_token");
    data.append("refresh_token", token.refresh_token);
    return await axios.post(AUTH_URL + `/api/v1/oauth2/upload/token`, data, { headers: this.getAuthHeader(client) })
    .then((response) => {
      token.upload_token = response.data.access_token;
      localStorage.setItem('token', JSON.stringify(token));
      return Promise.resolve(response.data);
    }).catch((error) => {
      return Promise.reject(error);
    })
  }

   /**
   * @param {String} endpoint
   * @param {JSON} object
   * @param {Function} handler
  */
  async upload( endpoint, func = 'getApiHost', object, handler ){
    const server = this[func]();
    return await axios({
      method: 'post',
      url: server + endpoint,
      data: object,
      headers: authHeader('upload_token'),
      onUploadProgress: handler
    });
  }

  /**
  * @param {String} endpoint
  * @param {String} func
  * @param {Boolean} header
  */
  async get(endpoint, func = 'getApiHost', header = true){
    const server = this[func]();
    header = { headers: authHeader('access_token') };
    if(!header){
      header = {};
    }
    else{
      header = { headers: authHeader('access_token') };
    }
    if( Array.isArray(endpoint) ){
      return await axios.all(endpoint.map((url) => axios.get(server + url, object, header)))
    }
    return await axios.get(server + endpoint, header);
  }

  /**
   * @param {String} endpoint
   * @param {JSON} object
   * @param {String} func
   * @param {Boolean} header
  */
  async post( endpoint, object, func = 'getApiHost', header = true){
    const server = this[func]();
    if(!header){
      header = {};
    }
    else{
      header = { headers: authHeader('access_token') };
    }
    if( Array.isArray(endpoint) ){
      return await axios.all(endpoint.map((url) => axios.post(server + url, object, header)))
    }
    return await axios.post(server + endpoint, object, header);
  }

  /**
   * @param {String} endpoint
   * @param {JSON} object
   * @param {String} func
   * @param {Boolean} header
  */
  async put( endpoint, object, func = 'getApiHost', header = true) {
    const server = this[func]();
    if(!header){
      header = {};
    }
    else{
      header = { headers: authHeader('access_token') };
    }
    if( Array.isArray(endpoint) ){
      return await axios.all(endpoint.map((url) => axios.put(server + url, object, header)))
    }
    return await axios.put(server + endpoint, object, header);
  }

  /**
   * @param {String} endpoint
   * @param {JSON} object
   * @param {String} func
   * @param {Boolean} header
  */
  async patch( endpoint, object, func = 'getApiHost', header = true) {
    const server = this[func]();
    if(!header){
      header = {};
    }
    else{
      header = { headers: authHeader('access_token') };
    }
    if( Array.isArray(endpoint) ){
      return await axios.all(endpoint.map((url) => axios.patch(server + url, object, header)))
    }
    return await axios.patch(server + endpoint, object, header);
  }

  /**
   * @param {String} endpoint
   * @param {String} func
   * @param {Boolean} header
  */
  async delete( endpoint, func = 'getApiHost', header = true) {
    const server = this[func]();
    if(!header){
      header = {};
    }
    else{
      header = { headers: authHeader('access_token') };
    }
    if( Array.isArray(endpoint) ){
      return await axios.all(endpoint.map((url) => axios.delete(server + url, header)))
    }
    return await axios.delete(server + endpoint, header);
  }
}

export default new APIService();

<template>
  <div>
    <v-row id="upload">
      <v-col cols="12" class="mt-4">
        <div class="m-4">
          <h3 class="font-weight-medium text--lighten-1 blue--text">
            Bild/er in Galerie hochladen
          </h3>
          <p class="mt-1 text-caption">Mögliche Bild-Formate: <span class="font-weight-bold">.png | .jpg | .jpeg</span></p>
          <p class="mt-n4 mt-1 text-caption">Maximale Größe: <span class="font-weight-bold">30MB</span></p>
        </div>
        <!-- <v-file-input
          v-model="image"
          label="Klicken um Bild auszuwählen"
          accept="image/png, image/jpeg, image/jpg"
          filled
          height="50px"
          :show-size="1000"
        ></v-file-input>
        <div class="d-flex justify-end">
          <v-btn @click="imageUpload()">Upload</v-btn>
        </div> -->

        <VueFileAgent
          ref="image"
          v-model="image"
          :theme="'grid'"
          :meta="true"
          :accept="'image/*'"
          :maxSize="'30MB'"
          :multiple="true"
          :maxFiles="300"
          :errorText="{
            type: 'Falscher Dateityp. Es sind nur Videos erlaubt.',
            size: 'Dateien dürfen nicht größer als 50MB sein.',
          }"
          :deletable="true"
          @beforedelete="onBeforeDeleteImage($event)"
          @delete="imageDeleted($event)"
        >
          <template v-slot:file-preview-new>
            <div key="new" class="file-preview-wrapper grid-box-item grid-block file-preview-new">
              <span class="file-preview">
                <div class="d-flex justify-center" style="position: absolute; width: 250px; top: 35%;">
                  <v-icon size="64" color="primary">mdi-image-plus</v-icon>
                  <span class="help-text text-caption pl-2">Bild per Drag &amp; Drop einfügen oder Ordner durchsuchen</span>
                </div>
              </span>
            </div>
          </template>
        </VueFileAgent>
      </v-col>
    </v-row>
    <div class="d-flex justify-end mt-6 mb-4">
      <v-btn class="font-weight-bold" color="green" outlined :disabled="image == '' || undefined" @click="imageUpload() ">
        <v-icon class="pr-2">mdi-cloud-upload-outline</v-icon>
        Upload starten
      </v-btn>
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppService from '@/services/app.service';
import APIService from '@/services/api.service';

export default {
  name: 'imageuploadInGallery',

  props: ['gallery'],

  data() {
    return {
      name:         '',
      videoTitle:   '',
      title:        '',
      description:  '',
      published:    false,
      transcoderActive:       '',
      image:                  [],
      fileRecordsForUpload:   [],
      loading:                Boolean,
      valid: false,
    }
  },

  computed: {
    ...mapGetters(["get"]),

    // responseInteractivities(){
    //   // Count all upload or convert responses
    //   return APIService.getVueElementById("response").interactivities.length;
    // }

  },

  methods: {

    isTester(){
      return AppService.isTester()
    },
    isCreatorSubActive(){
      return AppService.isCreatorSubActive()
    },

    openModal(name) {
      this.$store.dispatch("modal/open", name)
    },

    linkTo(route) {
      this.$router.push('/' + route)
    },

    imageUpload() {
      this.$store.dispatch('uploadFiles', {
        endpoint: `/api/v1/images`,
        files: {
            image: this.image
        },
        metadata: {
            published: false,
            price: 0, 
            subscription: false,
            comment: false
        },
        message: false
      }).then((response) => {
          
          const endpoint = [];
          response.forEach(
            id => {
              endpoint.push(`/api/v1/images/${id}`)
            }
          )
          this.$store.dispatch('patch', {
            endpoint: endpoint,
            event: { gallery: this.gallery },
            message: false
          }).then(() => {
            APIService.setResponse({message: 'Upload erfolgreich', color: "green"})        
            this.$store.dispatch('fetch', { endpoint: `/api/v1/galleries/${this.gallery}/images`, state: 'galleryImages' });
            this.image = []
          })
      })      
    },

    onBeforeDeleteImage(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        if (confirm('Das hier wirklich löschen?')) {
          this.$refs.image.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },

    imageDeleted(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.$refs.image.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
      }
    }

  },
}
</script>


<style>
#upload .vue-file-agent .file-category-video-playable .file-preview,
#upload .vue-file-agent .file-preview-wrapper-image .file-preview {
  background: transparent !important;
}

#upload .vue-file-agent .file-preview-new:before {
  background: transparent !important;
}

.vue-file-agent .file-preview {
  z-index: 2 !important;
}

/* Rahmen um die Dropzone  */
.vue-file-agent.file-input-wrapper{
  border-radius: 8px !important;
  border: 1px;
  border-style: dashed;
  border-color: #00c3ff;
}


/* Der Block worin das Icon enthalten ist*/
.grid-block-wrapper .grid-block{
  width: 35%;
  height: 20%;
}

/* Der Text unterhalb des Icons*/
/* .vue-file-agent .file-preview-new .help-text{
  font-size: 16px;
  color: lightgrey;
} */

.vue-file-agent .file-input {
  z-index: 1 !important;
}
</style>
